<template>
  <div>
    <v-toolbar dark dense :color="$store.getters.getColor('primary')" fixed>
      <v-btn icon @click="$router.go(-2);">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title class="espacamento-linhas pt-1">
        <span>Resumo</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon>
        <v-icon @click="getSimulacao">mdi-reload</v-icon>
      </v-btn>
    </v-toolbar>
    <div class="d-flex justify-center mt-10 pa-10" v-if="loading.page">
      <v-progress-circular indeterminate color="grey" size="50"></v-progress-circular>
    </div>
    <div v-else>
    <v-container class="pa-5 pb-5 pt-0">
      <v-expansion-panels multiple class="pb-5 pt-5 elevation-0" flat v-model="panel">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <h1 class="title font-weight-bold mb-1 primary--text" style="line-height: 1">
              Plano
            </h1>
          </v-expansion-panel-header>
          <v-divider class="mb-4"></v-divider>
          <v-expansion-panel-content active>
            <div class="row">
              <div class="col-12">
                <strong>Operadora</strong><br/>
                {{ dados.plano.operadora }}
              </div>
              <div class="col-12">
                <strong>Plano</strong><br/>
                {{ dados.plano.nomePlanoAns }}
              </div>
              <div class="col-12">
                <strong>Valor Total</strong><br/>
              {{ dados.plano.valorTotal | currency }}
              </div>
              <div class="col-4">
                <strong>Abrangência</strong><br/>
                {{ dados.plano.abrangencia }}
              </div>
              <div class="col-4">
                <strong>Acomodação</strong><br/>
                {{ dados.plano.acomodacao }}
              </div>
              <div class="col-4">
                <strong>Copartipação</strong><br/>
                {{ dados.plano.coparticipacao ? 'SIM' : 'NÃO' }}
              </div>
              <div class="col-12 break-text">
                <strong>Anotações</strong><br/>
                {{ dados.anotacao }}
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <h1 class="title font-weight-bold mb-1 primary--text" style="line-height: 1">
              Dados Titular
            </h1>
          </v-expansion-panel-header>
          <v-divider class="mb-4"></v-divider>
          <v-expansion-panel-content active>
            <div class="row">
              <div class="col-12">
                <strong>Nome</strong><br/>
                {{ dados.titular.nome }}
              </div>
              <!-- <div class="col-6">
                <strong>Data Nascimento</strong><br/>
                {{ formataNascimento(dados.titular.dataNascimento) }}
              </div> -->
              <div class="col-6">
                <strong>Cidade</strong><br/>
                {{ dados.endereco.cidade }}
              </div>
              <div class="col-6">
                <strong>Estado</strong><br/>
                {{ dados.endereco.estado }}
              </div>
              <div class="col-6">
                <strong>Profissão</strong><br/>
                {{ dados.titular.publicoAlvo }}
              </div>
              <div class="col-6">
                <strong>Entidade</strong><br/>
                {{ dados.plano.entidade }}
              </div>
              <div class="col-12">
                <strong>Email</strong><br/>
                {{ dados.titular.email }}
              </div>
              <div class="col-12">
                <strong>Telefone</strong><br/>
                {{ dados.titular.telefone.length > 0 ? formataTelefone(dados.titular.telefone[0].numero) : '--' }}
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <h1 class="title font-weight-bold mb-1 primary--text" style="line-height: 1">
              Faixas Etárias
            </h1>
          </v-expansion-panel-header>
          <v-divider class="mb-4"></v-divider>
          <v-expansion-panel-content active>
            <v-row v-if="dados.faixaEtaria.length > 0" class="ml-2">
              <v-col class="col-12" v-for="(faixaEtaria, index) in dados.faixaEtaria" :key="index">
                {{ faixaEtaria }}
              </v-col>
            </v-row>
            <v-row v-else class="ml-2">
              <v-col class="col-12">
                Não há beneficiarios
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <h1 class="title font-weight-bold mb-1 primary--text" style="line-height: 1">Rede Médica</h1>
          </v-expansion-panel-header>
          <v-divider class="mb-4"></v-divider>
          <v-expansion-panel-content active>
            <v-row class="mt-1">
              <v-col cols="12">
                <base-autocomplete-field
                  :disabled="cidades.length < 1"
                  :items="cidades"
                  rules="required"
                  id="cidade"
                  name="Cidade"
                  label="Cidade"
                  v-model="selCidade"
                  @input="filtrarPrestadores"
                  mode="aggressive"
                  outlined
                  return-object
                />
              </v-col>
            </v-row>
            <v-list v-for="(item, index) in prestadorasFiltradas" :key="index">
              <v-list-item>
                <v-list-item-content>
                  <strong>{{ item.prestador }}</strong><br/>
                  <div class="mt-1">
                    <span><v-chip small color="primary" label class="d-inline">{{ item.tipoPrestador }}</v-chip></span>
                    <div class="mt-2">
                      <span class="mr-1" v-for="(tag, index) in item.tipoAtendimento" :key="index">
                        <v-chip small color="grey lighten-1" v-if="tag !== 'LAB'" class="mb-3">
                          {{ getTipoAtendimento(tag) }}
                        </v-chip>
                      </span>
                    </div>
                  </div>
                </v-list-item-content>
              </v-list-item>
              <v-divider v-if="index < prestadorasFiltradas.length - 1" />
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <h1 class="title font-weight-bold mb-1 primary--text" style="line-height: 1">Reajuste anual</h1>
          </v-expansion-panel-header>
          <v-divider class="mb-4"></v-divider>
          <v-expansion-panel-content active>
            <v-row class="mt-1">
              <v-col cols="12">
                {{ meses[dados.plano.mesReajuste] }}
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-row class="acoes" justify="center">
        <v-col class="col-12 col-md-3">
          <v-btn color="primary" class="mb-2" block outlined @click="editar" :loading="loading.editar">Editar Dados</v-btn>
        </v-col>
        <v-col class="col-12 col-md-3">
          <v-btn color="primary" class="mb-2" block @click="contratar">Contratar</v-btn>
        </v-col>
        <v-col class="col-12 col-md-3">
          <v-btn color="red" class="mb-2 white--text" block @click="dialog='true'" :loading="loading.excluir">Excluir</v-btn>
        </v-col>
      </v-row>
    </v-container>
    </div>
    <v-dialog
      v-model="dialog"
      max-width="290"
    >
    <v-card>
        <v-card-title class="headline">
          Excluir simulação
        </v-card-title>
        <v-card-text>Deseja realmente excluir:</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            text
            @click="dialog = false"
          >
            Não
          </v-btn>
          <v-btn
            color="primary darken-1"
            text
            @click="remover"
            :loading="loading.excluir"
          >
            Sim
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import BaseAutocompleteField from "@/components/BaseAutocompleteField";
import simulacaoService from '@/services/simulacaoService';
import redeService from "@/services/redeReferenciadaService";
import Meses from "@/utils/constants/Meses";
import { mapActions, mapState } from 'vuex';
import moment from 'moment';
import filters from "@/filters";

export default {
  name: 'resumo-simulacao',
  components: {
    BaseAutocompleteField,
  },
  data() {
    return {
      dados: {},
      dialog: false,
      panel: [0, 1, 2, 3],
      selCidade: null,
      cidades: [],
      prestadores: [],
      meses: Meses,
      prestadorasFiltradas: [],
      loading: {
        page: true,
        editar: false,
        excluir: false,
      },
    };
  },
  async created() {
    await this.getSimulacao();
    await this.getCidades();
  },
  computed: {
    celularTitular() {
      if (
        !(
          this.dados
          && this.dados.titular
          && this.dados.titular.telefone
        )
      ) { return ""; }
      return this.dados.titular.telefone.find(
        (i) => i.tipo && (i.preferencial === true || i.preferencial === false),
      );
    },
    enderecoResidencial() {
      if (
        !(
          this.dados
          && this.dados.endereco
        )
      ) { return ""; }
      return {
        ...this.dados.endereco,
        tipo: "ENDERECO_RESIDENCIAL",
      };
    },
  },
  methods: {
    ...mapActions({
      setDadosProposta: 'cadastroProposta/setDadosProposta',
      setTitular: 'simulacao/setTitular',
      setEndereco: 'simulacao/setEndereco',
      setAnotacao: 'simulacao/setAnotacao',
      setPlano: 'simulacao/setPlano',
      setEditando: 'simulacao/setEditando',
      setDependentes: 'simulacao/setDependentes',
      setGuid: 'simulacao/setGuid',
      setAdministradora: 'simulacao/setAdministradora',
      setDadosTitular: "cadastroProposta/setDadosTitular",
      setDadosDependentes: 'cadastroProposta/setDadosDependentes',
      setBlnContinuar: 'cadastroProposta/setBlnContinuar',
      resetDadosProposta: 'cadastroProposta/resetDadosProposta',
    }),
    async getSimulacao() {
      try {
        const data = await simulacaoService.getSimulacao({ guid: this.$route.params.guid });
        [this.dados] = data;
        this.dados.faixaEtaria = []
        this.dados.titular ? this.dados.faixaEtaria.push(filters.calcularFaixaEtaria(this.dados.titular.dataNascimento)) : null
        if (this.dados.dependentes && this.dados.dependentes.length > 0) {
          for (const item of this.dados.dependentes) {
            this.dados.faixaEtaria.push(filters.calcularFaixaEtaria(item.dataNascimento))
          }
        }
        this.loading.page = false;
      } catch (error) {
        this.$root.$snackBar.open({
          color: 'error',
          message: error.message,
        });
      }
    },
    async getCidades() {
      try {
        const { plano, endereco } = this.dados;
        const { idProdutoFatura } = plano;
        const { estado } = endereco;
        if (idProdutoFatura && estado) {
          const { data } = await redeService.getRedeReferenciada({ idProdutoFatura: [idProdutoFatura], UF: estado });
          this.cidades = data && data.cidades ? data.cidades.sort((a, b) => a.localeCompare(b)) : [];
          this.prestadores = data && data.prestadores;
        }
      } catch (error) {
        this.cidades = [];
        this.prestadores = [];
      }
    },
    filtrarPrestadores(value) {
      const prestadores = this.prestadores.filter((p) => p.cidade === value);
      this.prestadorasFiltradas = prestadores.sort((a, b) => a.prestador.localeCompare(b.prestador));
    },
    getTipoAtendimento(tag) {
      const tiposAtendimento = {
        INT: "Internação",
        MAT: "Maternidade",
        PS: "Pronto Socorro"
      };
      return tiposAtendimento[tag.toUpperCase()] ? tiposAtendimento[tag.toUpperCase()] : tag;
    },
    formataNascimento(data) {
      return moment(data).format('DD/MM/YYYY');
    },
    formataTelefone(tel) {
      if (tel && tel !== 'undefined') return `${tel.substring(0, 2)} ${tel.substring(2, 7)}-${tel.substring(7, 11)}`;
      return '--';
    },

    editar() {
      this.loading.editar = true;
      const {
        titular, anotacao, endereco, dependentes, guid, administradora,
      } = this.dados;
      this.setGuid({ guid });
      this.setTitular({ titular });
      this.setAnotacao({ anotacao });
      this.setEndereco({ endereco });
      this.setDependentes({ dependentes });
      this.setAdministradora({ administradora });
      this.setEditando({ editando: true });
      this.$router.push({ name: 'areaLogada.criarSimulacao' });
      this.loading.editar = false;
    },
    contratar() {
      this.resetProposta();
      const { administradora } = this.dados;
      const obj = {
        ...this.dados.titular,
        ...{
          telefones: this.celularTitular ? [this.celularTitular] : [],
        },
        enderecos: this.tratarEnderecos(),
        entidade: this.dados.plano.entidade,
      };
      obj.dataNascimento = ""
      this.setDadosTitular(obj);
      const deps = this.dados.dependentes.map(
        ({ parentesco, ...resto }) => ({
          dataNascimento: "",
        }),
      );
      if (deps.length > 0) {
        this.setDadosDependentes(deps);
      }
      this.setAdministradora({ administradora });
      this.setBlnContinuar({ flag: true });
      this.$router.push({ name: 'areaLogada.criarProposta' });
    },
    async remover() {
      this.loading.excluir = true;
      try {
        const data = await simulacaoService.remover({ guid: this.$route.params.guid });
        this.$router.push({ name: 'areaLogada.simulacoes' });
        this.$root.$snackBar.open({
          color: 'success',
          message: 'A simulação foi excluída',
        });
      } catch (error) {
        this.loading.excluir = false;
        this.$root.$snackBar.open({
          color: 'error',
          message: error.message,
        });
      }
    },
    tratarEnderecos() {
      const enderecos = [];
      if (
        this.dados
        && this.dados.endereco
      ) {
        enderecos.push({
          bairro: this.dados.endereco.bairro,
          cep: this.dados.endereco.cep,
          cidade: this.dados.endereco.cidade,
          complemento: this.dados.endereco.complemento,
          estado: this.dados.endereco.estado,
          logradouro: this.dados.endereco.logradouro,
          numero: this.dados.endereco.numero,
          tipo: ['ENDERECO_RESIDENCIAL'],
        });
      }
      return enderecos;
    },
    resetProposta() {
      this.resetDadosProposta();
      this.setDadosProposta({
        titular: {},
        dependentes: [],
        corretora: {},
        plano: {},
        dataVigencia: "",
        dadosProfissionais: {},
        dadosComplementaresFiliacao: {},
        pagamento: {},
        produtosOpcionais: [],
        objEtapas: {},
      });
    },
  },
};

</script>
<style lang="scss" scoped>
.break-text {
  word-break: break-all;
}
</style>
