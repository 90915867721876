var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-toolbar",
        {
          attrs: {
            dark: "",
            dense: "",
            color: _vm.$store.getters.getColor("primary"),
            fixed: "",
          },
        },
        [
          _c(
            "v-btn",
            {
              attrs: { icon: "" },
              on: {
                click: function ($event) {
                  return _vm.$router.go(-2)
                },
              },
            },
            [_c("v-icon", [_vm._v("mdi-arrow-left")])],
            1
          ),
          _c("v-toolbar-title", { staticClass: "espacamento-linhas pt-1" }, [
            _c("span", [_vm._v("Resumo")]),
          ]),
          _c("v-spacer"),
          _c(
            "v-btn",
            { attrs: { icon: "" } },
            [
              _c("v-icon", { on: { click: _vm.getSimulacao } }, [
                _vm._v("mdi-reload"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm.loading.page
        ? _c(
            "div",
            { staticClass: "d-flex justify-center mt-10 pa-10" },
            [
              _c("v-progress-circular", {
                attrs: { indeterminate: "", color: "grey", size: "50" },
              }),
            ],
            1
          )
        : _c(
            "div",
            [
              _c(
                "v-container",
                { staticClass: "pa-5 pb-5 pt-0" },
                [
                  _c(
                    "v-expansion-panels",
                    {
                      staticClass: "pb-5 pt-5 elevation-0",
                      attrs: { multiple: "", flat: "" },
                      model: {
                        value: _vm.panel,
                        callback: function ($$v) {
                          _vm.panel = $$v
                        },
                        expression: "panel",
                      },
                    },
                    [
                      _c(
                        "v-expansion-panel",
                        [
                          _c("v-expansion-panel-header", [
                            _c(
                              "h1",
                              {
                                staticClass:
                                  "title font-weight-bold mb-1 primary--text",
                                staticStyle: { "line-height": "1" },
                              },
                              [_vm._v(" Plano ")]
                            ),
                          ]),
                          _c("v-divider", { staticClass: "mb-4" }),
                          _c(
                            "v-expansion-panel-content",
                            { attrs: { active: "" } },
                            [
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-12" }, [
                                  _c("strong", [_vm._v("Operadora")]),
                                  _c("br"),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.dados.plano.operadora) +
                                      " "
                                  ),
                                ]),
                                _c("div", { staticClass: "col-12" }, [
                                  _c("strong", [_vm._v("Plano")]),
                                  _c("br"),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.dados.plano.nomePlanoAns) +
                                      " "
                                  ),
                                ]),
                                _c("div", { staticClass: "col-12" }, [
                                  _c("strong", [_vm._v("Valor Total")]),
                                  _c("br"),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("currency")(
                                          _vm.dados.plano.valorTotal
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c("div", { staticClass: "col-4" }, [
                                  _c("strong", [_vm._v("Abrangência")]),
                                  _c("br"),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.dados.plano.abrangencia) +
                                      " "
                                  ),
                                ]),
                                _c("div", { staticClass: "col-4" }, [
                                  _c("strong", [_vm._v("Acomodação")]),
                                  _c("br"),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.dados.plano.acomodacao) +
                                      " "
                                  ),
                                ]),
                                _c("div", { staticClass: "col-4" }, [
                                  _c("strong", [_vm._v("Copartipação")]),
                                  _c("br"),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.dados.plano.coparticipacao
                                          ? "SIM"
                                          : "NÃO"
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "col-12 break-text" },
                                  [
                                    _c("strong", [_vm._v("Anotações")]),
                                    _c("br"),
                                    _vm._v(
                                      " " + _vm._s(_vm.dados.anotacao) + " "
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-expansion-panel",
                        [
                          _c("v-expansion-panel-header", [
                            _c(
                              "h1",
                              {
                                staticClass:
                                  "title font-weight-bold mb-1 primary--text",
                                staticStyle: { "line-height": "1" },
                              },
                              [_vm._v(" Dados Titular ")]
                            ),
                          ]),
                          _c("v-divider", { staticClass: "mb-4" }),
                          _c(
                            "v-expansion-panel-content",
                            { attrs: { active: "" } },
                            [
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-12" }, [
                                  _c("strong", [_vm._v("Nome")]),
                                  _c("br"),
                                  _vm._v(
                                    " " + _vm._s(_vm.dados.titular.nome) + " "
                                  ),
                                ]),
                                _c("div", { staticClass: "col-6" }, [
                                  _c("strong", [_vm._v("Cidade")]),
                                  _c("br"),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.dados.endereco.cidade) +
                                      " "
                                  ),
                                ]),
                                _c("div", { staticClass: "col-6" }, [
                                  _c("strong", [_vm._v("Estado")]),
                                  _c("br"),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.dados.endereco.estado) +
                                      " "
                                  ),
                                ]),
                                _c("div", { staticClass: "col-6" }, [
                                  _c("strong", [_vm._v("Profissão")]),
                                  _c("br"),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.dados.titular.publicoAlvo) +
                                      " "
                                  ),
                                ]),
                                _c("div", { staticClass: "col-6" }, [
                                  _c("strong", [_vm._v("Entidade")]),
                                  _c("br"),
                                  _vm._v(
                                    " " + _vm._s(_vm.dados.plano.entidade) + " "
                                  ),
                                ]),
                                _c("div", { staticClass: "col-12" }, [
                                  _c("strong", [_vm._v("Email")]),
                                  _c("br"),
                                  _vm._v(
                                    " " + _vm._s(_vm.dados.titular.email) + " "
                                  ),
                                ]),
                                _c("div", { staticClass: "col-12" }, [
                                  _c("strong", [_vm._v("Telefone")]),
                                  _c("br"),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.dados.titular.telefone.length > 0
                                          ? _vm.formataTelefone(
                                              _vm.dados.titular.telefone[0]
                                                .numero
                                            )
                                          : "--"
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-expansion-panel",
                        [
                          _c("v-expansion-panel-header", [
                            _c(
                              "h1",
                              {
                                staticClass:
                                  "title font-weight-bold mb-1 primary--text",
                                staticStyle: { "line-height": "1" },
                              },
                              [_vm._v(" Faixas Etárias ")]
                            ),
                          ]),
                          _c("v-divider", { staticClass: "mb-4" }),
                          _c(
                            "v-expansion-panel-content",
                            { attrs: { active: "" } },
                            [
                              _vm.dados.faixaEtaria.length > 0
                                ? _c(
                                    "v-row",
                                    { staticClass: "ml-2" },
                                    _vm._l(
                                      _vm.dados.faixaEtaria,
                                      function (faixaEtaria, index) {
                                        return _c(
                                          "v-col",
                                          { key: index, staticClass: "col-12" },
                                          [
                                            _vm._v(
                                              " " + _vm._s(faixaEtaria) + " "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    1
                                  )
                                : _c(
                                    "v-row",
                                    { staticClass: "ml-2" },
                                    [
                                      _c("v-col", { staticClass: "col-12" }, [
                                        _vm._v(" Não há beneficiarios "),
                                      ]),
                                    ],
                                    1
                                  ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-expansion-panel",
                        [
                          _c("v-expansion-panel-header", [
                            _c(
                              "h1",
                              {
                                staticClass:
                                  "title font-weight-bold mb-1 primary--text",
                                staticStyle: { "line-height": "1" },
                              },
                              [_vm._v("Rede Médica")]
                            ),
                          ]),
                          _c("v-divider", { staticClass: "mb-4" }),
                          _c(
                            "v-expansion-panel-content",
                            { attrs: { active: "" } },
                            [
                              _c(
                                "v-row",
                                { staticClass: "mt-1" },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("base-autocomplete-field", {
                                        attrs: {
                                          disabled: _vm.cidades.length < 1,
                                          items: _vm.cidades,
                                          rules: "required",
                                          id: "cidade",
                                          name: "Cidade",
                                          label: "Cidade",
                                          mode: "aggressive",
                                          outlined: "",
                                          "return-object": "",
                                        },
                                        on: { input: _vm.filtrarPrestadores },
                                        model: {
                                          value: _vm.selCidade,
                                          callback: function ($$v) {
                                            _vm.selCidade = $$v
                                          },
                                          expression: "selCidade",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._l(
                                _vm.prestadorasFiltradas,
                                function (item, index) {
                                  return _c(
                                    "v-list",
                                    { key: index },
                                    [
                                      _c(
                                        "v-list-item",
                                        [
                                          _c("v-list-item-content", [
                                            _c("strong", [
                                              _vm._v(_vm._s(item.prestador)),
                                            ]),
                                            _c("br"),
                                            _c("div", { staticClass: "mt-1" }, [
                                              _c(
                                                "span",
                                                [
                                                  _c(
                                                    "v-chip",
                                                    {
                                                      staticClass: "d-inline",
                                                      attrs: {
                                                        small: "",
                                                        color: "primary",
                                                        label: "",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.tipoPrestador
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "mt-2" },
                                                _vm._l(
                                                  item.tipoAtendimento,
                                                  function (tag, index) {
                                                    return _c(
                                                      "span",
                                                      {
                                                        key: index,
                                                        staticClass: "mr-1",
                                                      },
                                                      [
                                                        tag !== "LAB"
                                                          ? _c(
                                                              "v-chip",
                                                              {
                                                                staticClass:
                                                                  "mb-3",
                                                                attrs: {
                                                                  small: "",
                                                                  color:
                                                                    "grey lighten-1",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.getTipoAtendimento(
                                                                        tag
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                0
                                              ),
                                            ]),
                                          ]),
                                        ],
                                        1
                                      ),
                                      index <
                                      _vm.prestadorasFiltradas.length - 1
                                        ? _c("v-divider")
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                }
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-expansion-panel",
                        [
                          _c("v-expansion-panel-header", [
                            _c(
                              "h1",
                              {
                                staticClass:
                                  "title font-weight-bold mb-1 primary--text",
                                staticStyle: { "line-height": "1" },
                              },
                              [_vm._v("Reajuste anual")]
                            ),
                          ]),
                          _c("v-divider", { staticClass: "mb-4" }),
                          _c(
                            "v-expansion-panel-content",
                            { attrs: { active: "" } },
                            [
                              _c(
                                "v-row",
                                { staticClass: "mt-1" },
                                [
                                  _c("v-col", { attrs: { cols: "12" } }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.meses[_vm.dados.plano.mesReajuste]
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "acoes", attrs: { justify: "center" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "col-12 col-md-3" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mb-2",
                              attrs: {
                                color: "primary",
                                block: "",
                                outlined: "",
                                loading: _vm.loading.editar,
                              },
                              on: { click: _vm.editar },
                            },
                            [_vm._v("Editar Dados")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "col-12 col-md-3" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mb-2",
                              attrs: { color: "primary", block: "" },
                              on: { click: _vm.contratar },
                            },
                            [_vm._v("Contratar")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "col-12 col-md-3" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mb-2 white--text",
                              attrs: {
                                color: "red",
                                block: "",
                                loading: _vm.loading.excluir,
                              },
                              on: {
                                click: function ($event) {
                                  _vm.dialog = "true"
                                },
                              },
                            },
                            [_vm._v("Excluir")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "290" },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v(" Excluir simulação "),
              ]),
              _c("v-card-text", [_vm._v("Deseja realmente excluir:")]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "red darken-1", text: "" },
                      on: {
                        click: function ($event) {
                          _vm.dialog = false
                        },
                      },
                    },
                    [_vm._v(" Não ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary darken-1",
                        text: "",
                        loading: _vm.loading.excluir,
                      },
                      on: { click: _vm.remover },
                    },
                    [_vm._v(" Sim ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }